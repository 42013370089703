/**
 * wraps up the callbackFn and captures any error thrown from it, console.warn'ing the error message
 *
 * @param messageIdentifier
 * @returns {function(*): Function}
 */
export const traceMessageOnError =
  (messageIdentifier) =>
  (callbackFn) =>
  (...args) => {
    try {
      callbackFn(...args)
    } catch (err) {
      console.warn(messageIdentifier, err.message)
    }
  }
