import {
  fromPairs,
  has,
  identity,
  ifElse,
  lensPath,
  map,
  over,
  pipe,
  propOr,
  set,
  toPairs,
} from 'ramda'

const cdnLens = lensPath(['application', 'cdn'])

export const transformLocalCdn = (localCdn) => set(cdnLens, localCdn)
export const transformCdn = (cdnKey) => over(cdnLens, propOr('', cdnKey))

export const transformManifest = (applicationFn) =>
  map(ifElse(has('application'), applicationFn, identity))

const URL_TRANSFORMERS = [
  {
    pattern: /^\*:(\d+)(\/.*)$/,
    matches(appPath) {
      return this.pattern.test(appPath)
    },
    transform(appPath, location) {
      const [, port, pathname] = appPath.match(this.pattern)
      return {
        origin: `${location.protocol}//${location.hostname}:${port}`,
        pathname,
      }
    },
  },
  {
    pattern: /^(https?:\/\/.*?)(\/.*)$/,
    matches(appPath) {
      return this.pattern.test(appPath)
    },
    transform(appPath) {
      const [, origin, pathname] = appPath.match(this.pattern)
      return { origin, pathname }
    },
  },
  {
    pattern: /^\*(\/.*)$/,
    matches(appPath) {
      return this.pattern.test(appPath)
    },
    transform(appPath, location) {
      const [, pathname] = appPath.match(this.pattern)
      return { origin: location.origin, pathname }
    },
  },
]

export const transformUrl = (appPath, location) => {
  for (let i = 0; i < URL_TRANSFORMERS.length; i++) {
    const transformer = URL_TRANSFORMERS[i]
    if (transformer.matches(appPath)) {
      return transformer.transform(appPath, location)
    }
  }
  return { origin: location.origin, pathname: appPath }
}

export function transformLocalManifestMap(manifestMap, location) {
  return pipe(
    toPairs,
    map(([appId, appPath]) => [appId, transformUrl(appPath, location)]),
    fromPairs
  )(manifestMap)
}
