import { initializePendo } from './initialize-pendo'
import { initializePaychexPendo } from './initialize-paychex-pendo'
import { injectPendo } from './inject-pendo'
import { injectPaychexPendo } from './inject-paychex-pendo'

const PENDO_TARGET_ROLES = ['account_admin', 'admin', 'author', 'it_admin']

export const PENDO_LOCAL_KEYNAME = 'pendo'
export const PENDO_LOCAL_INFO_DAYS_TO_LIVE = 30

export const isInPendoRole = (user) => {
  if (!user) return false

  const { roles } = user
  if (!roles) return false

  return roles.find((role) => PENDO_TARGET_ROLES.includes(role)) ? true : false
}

export const shouldInitPendo = (session) => {
  if (!session) return false

  const { user } = session
  // Always show Pendo guides to particular roles.
  // Always allow for these roles, even for the ".suite" domain.
  if (isInPendoRole(user)) return true

  return false
}

export const isPaychexAccount = (account) => {
  return account?.tenant.includes('paychex')
}

export const isPaychexPendoEnvironment = () => {
  return (
    process.env.DEPLOY_ENV === 'staging' ||
    process.env.DEPLOY_ENV === 'prod' ||
    process.env.DEPLOY_ENV === 'production'
  )
}

export function load() {
  return ({ account, session }) => {
    // If this is a Paychex account/subaccount, then include Paychex-specific Pendo script.
    if (isPaychexPendoEnvironment() && isPaychexAccount(account)) {
      injectPaychexPendo()

      initializePaychexPendo(session, account)
    } else {
      if (process.env.PENDO_API_KEY) {
        injectPendo()

        // This check only applies to non-Paychex accounts.
        if (!shouldInitPendo(session)) return

        initializePendo(session, account)
      }
    }
  }
}
