import * as R from 'ramda'

/**
 * @typedef {import("../type-defs")}
 * @typedef {string} PendoApiKey
 */

/**
 * Initializes Pendo.
 *
 * @param {Session} session
 * @param {Domain} domain
 * @param {Object} [options]
 * @param {PendoApiKey} [options.apiKey]
 * @param {Environment} [options.env]
 */
export function initializePendo(
  session,
  domain,
  { apiKey = process.env.PENDO_API_KEY, env = process.env.DEPLOY_ENV } = {}
) {
  if (hasNeededData({ apiKey, env, session, domain })) {
    window.pendo.initialize(
      toPendoConfiguration({ apiKey, domain, env, session })
    )
  }
}

/**
 * Determines if the necessary pieces of data for initializing Pendo are available.
 *
 * @param {Object} [options]
 * @param {PendoApiKey} [options.apiKey]
 * @param {Environment} [options.env]
 * @param {Session} [options.session]
 * @param {Domain} [options.domain]
 */
const hasNeededData = ({ apiKey, env, session, domain }) => {
  return !!(
    apiKey &&
    session &&
    session.user &&
    domain &&
    domain.id &&
    domain.regionCode &&
    domain.account &&
    domain.account.id &&
    domain.account.database &&
    env
  )
}

/**
 * Creates a selector for determining if the specified product is enabled.
 * @param {ProductName} productName
 */
const isProductEnabled = (productName) =>
  R.pipe(
    R.pathOr([], ['domain', 'config', 'products']),
    R.any(
      R.allPass([
        R.prop('subscriptionType'),
        R.propEq('productName', productName),
      ])
    )
  )

/**
 * Migrates environment name to match Learn ('prod' -> 'production')
 * @param {Environment} env
 * @returns {Environment}
 */
const getEnv = (env) =>
  R.compose(R.replace(/\bprod\b/g, 'production'), R.toLower, env)

/**
 * @typedef {Object} PendoConfiguration
 * @property {string} apiKey
 * @property {Object} account
 * @property {string} account.id
 * @property {string} account.name
 * @property {string} account.environment
 * @property {string} account.region
 * @property {string} account.salesforceId
 * @property {string} account.tenantId
 * @property {string} account.tenantName
 * @property {string} account.tenantDatabase
 * @property {string} [account.tenantSubdomain]
 * @property {AccountType} account.tenantType
 * @property {boolean} account.careerEnabled
 * @property {boolean} account.connectEnabled
 * @property {boolean} account.engageEnabled
 * @property {boolean} account.learnEnabled
 * @property {boolean} account.performEnabled
 * @property {Object} visitor
 * @property {string} visitor.id
 * @property {string} visitor.isMasquerading
 * @property {string} visitor.locale
 * @property {string[]} visitor.roles
 * @property {string} visitor.userId
 * @property {string} visitor.domainId
 * @property {string} visitor.domainName
 * @property {string} [visitor.domainSubdomain]
 */

/**
 * Creates Pendo configuration.
 *
 * @param {Object} options
 * @param {Session} options.session
 * @param {Domain} options.domain
 * @param {PendoApiKey} options.apiKey
 * @param {Environment} options.env
 * @returns {PendoConfiguration}
 */
export const toPendoConfiguration = (args) => {
  const config = R.applySpec({
    apiKey: R.prop('apiKey'),
    account: {
      id: R.compose(
        R.toLower,
        R.join('___'),
        R.juxt([
          R.path(['domain', 'account', 'database']),
          R.path(['domain', 'regionCode']),
        ])
      ),
      name: R.compose(
        R.unless(R.isNil, R.toLower),
        R.path(['domain', 'account', 'name'])
      ),
      environment: getEnv(R.path(['env'])),
      region: R.compose(R.toLower, R.path(['domain', 'regionCode'])),
      salesforceId: R.compose(
        R.toUpper,
        R.pathOr('NO_SALESFORCE_ID', [
          'domain',
          'account',
          'config',
          'salesforceId',
        ])
      ),
      tenantId: R.path(['domain', 'account', 'id']),
      tenantName: R.compose(
        R.unless(R.isNil, R.toLower),
        R.path(['domain', 'account', 'name'])
      ),
      tenantDatabase: R.path(['domain', 'account', 'database']),
      tenantSubdomain: R.compose(
        R.unless(R.isNil, R.toLower),
        R.path(['domain', 'account', 'host', 'subdomain'])
      ),
      tenantType: R.compose(
        R.toUpper,
        R.path(['domain', 'account', 'accountType'])
      ),
      careerEnabled: isProductEnabled('career'),
      connectEnabled: isProductEnabled('connect'),
      engageEnabled: isProductEnabled('engage'),
      learnEnabled: isProductEnabled('learn'),
      performEnabled: isProductEnabled('perform'),
    },
    visitor: {
      id: R.compose(
        R.toLower,
        R.join('___'),
        R.juxt([
          R.path(['domain', 'account', 'database']),
          R.path(['domain', 'regionCode']),
          R.path(['session', 'user', 'id']),
        ])
      ),
      isMasquerading: R.pathOr(false, ['session', 'meta', 'isMasquerading']),
      locale: R.compose(R.toLower, R.pathOr('', ['session', 'user', 'locale'])),
      userId: R.path(['session', 'user', 'id']),
      domainId: R.path(['domain', 'id']),
      domainName: R.compose(R.toLower, R.path(['domain', 'name'])),
      domainSubdomain: R.compose(
        R.unless(R.isNil, R.toLower),
        R.path(['domain', 'host', 'subdomain'])
      ),
      roles: R.pipe(
        R.pathOr([], ['session', 'user', 'roles']),
        R.map((r) => ({ [`is${r.toUpperCase()}`]: true })),
        R.reduce((acc, curr) => {
          return Object.assign({}, acc, curr)
        }, {})
      ),
    },
  })(args)

  // A dirty hack necessary to get each of the roles aplied to the user
  // onto the nested userVars object. I couldn't figure out a way to
  // accomplish the samething purely within the R.applySpec().
  const returnObj = {
    ...config,
    visitor: {
      ...config.visitor,
      ...config.visitor.roles,
    },
  }
  delete returnObj.visitor.roles
  return returnObj
}
