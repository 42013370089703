// [['   cookieName ', ' cookieValue     ']] => [['cookieName', 'cookieValue']]
const cookieCleaner = (a) => a.map((s) => s.trim())
const cookieFinder = (cookieName) => (a) => a[0] === cookieName
const cookieParser = (s) => s.split('=')

/**
 * Fetches cookie based on cookie name.
 * Removes cookie after reading!
 *
 * @param {string} cookieName - the cookie to read
 *
 * @returns {string|null} the fetched cookie
 */
export function getAndDeleteCookie(cookieName) {
  const parsedCookies = document.cookie.split(';').map(cookieParser)
  const cleanedCookies = parsedCookies.map(cookieCleaner)

  const cookie = cleanedCookies.find(cookieFinder(cookieName))

  if (cookie !== undefined) {
    document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    return cookie[1]
  }
  return null
}
