import { getConfig, toUserId, toUserConfig } from './configure-google-analytics'
import { createTitleMutationObserver } from './title-mutation-observer'
import { loadGoogleAnalyticsLib } from './load-google-analytics-lib'

/**
 * Loads and initializes Google Analytics.
 */
export const loadGoogleAnalytics = () =>
  loadGoogleAnalyticsLib().then(initializeGoogleAnalytics)

/**
 * Ensures that no automatic page_view event is logged on page load.
 * https://github.com/firebase/firebase-js-sdk/issues/3988
 */
function disableAutomaticPageView() {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('set', { send_page_view: false })
}

/**
 * Initializes Google Analytics for the given user.
 * @param {Object} firebase - the loaded Firebase instance
 */
function initializeGoogleAnalytics(firebase) {
  disableAutomaticPageView()
  firebase.initializeApp(getConfig())
  firebase.performance()

  return ({ account: domain, session }) => {
    if (session) {
      firebase.analytics().setUserId(toUserId(session))
      firebase.analytics().setUserProperties(toUserConfig({ session, domain }))
      const mutationOptions = {
        observeOptionsOverride: {
          childList: false, // don't log title textContent changes (only analyticsTitle)
        },
      }

      createTitleMutationObserver((page_title) => {
        firebase.analytics().logEvent('page_view', { page_title })
      }, mutationOptions)
    }

    firebase.analytics()

    return {
      analytics: {
        logEvent(eventType, eventParameters = {}) {
          firebase.analytics().logEvent(eventType, eventParameters)
        },
      },
    }
  }
}

/**
 * Creates a stub Google Analytics instance.
 */
export const loadGoogleAnalyticsStub = () => () => {
  function logEvent(eventType, eventParameters = {}) {
    if (window.__TAPESTRY_DEBUG_GA__) {
      console.log(`GA[${eventType}]`, eventParameters)
    }
  }

  createTitleMutationObserver((page_title) => {
    logEvent('page_view', { page_title })
  })

  return {
    analytics: { logEvent },
  }
}
