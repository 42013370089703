import mitt from 'mitt'
import { curry } from 'ramda'

const HCM_EVENT = 'set-hcm'
const UNDERLINE_EVENT = 'set-underline-links'

export function createEmitter() {
  const emitter = mitt()
  return {
    emitter,
    unsubscribe() {
      emitter.all.clear()
    },
  }
}

function subscribe(emitter, eventName, callback) {
  emitter.on(eventName, callback)
  return () => {
    emitter.off(eventName, callback)
  }
}

const sendBooleanEvent = curry((emitter, prop, enabled) => {
  emitter.emit(prop, { enabled })
})

function setBooleanProp(prop, enabled) {
  if (enabled) {
    document.body.setAttribute(prop, '')
  } else {
    document.body.removeAttribute(prop)
  }
}

const setHighContrastMode =
  (isDisabled) =>
  ({ enabled = false } = {}) => {
    const isEnabled = !isDisabled() && enabled
    setBooleanProp('data-high-contrast', isEnabled)
  }

function setUnderlineLinks({ enabled = false } = {}) {
  setBooleanProp('data-underline-links', enabled)
}

export function subscribeToHCMEvent(emitter, isDisabledFn) {
  const emit = sendBooleanEvent(emitter, HCM_EVENT)
  const unsubscribe = subscribe(
    emitter,
    HCM_EVENT,
    setHighContrastMode(isDisabledFn)
  )
  return { emit, unsubscribe }
}

export function subscribeToUnderlineEvent(emitter) {
  const emit = sendBooleanEvent(emitter, UNDERLINE_EVENT)
  const unsubscribe = subscribe(emitter, UNDERLINE_EVENT, setUnderlineLinks)
  return { emit, unsubscribe }
}
