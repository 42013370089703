import { createHolder } from '../../utils'
import { fetchFeatureFlags, isFeatureEnabled } from './feature-flag-system'

export function createFeatureFlagService({
  featureFlags,
  featureFlagsDefault = false,
} = {}) {
  const featuresHolder = createHolder(featureFlags)

  return {
    fetch() {
      return featuresHolder.get()
        ? Promise.resolve(featuresHolder.get())
        : fetchFeatureFlags().then((fetchedFeatures) => {
            featuresHolder.set(fetchedFeatures)
            return fetchedFeatures
          })
    },
    isFeatureEnabled(feature) {
      return isFeatureEnabled(featuresHolder.get(), feature, {
        defaultValue: featureFlagsDefault,
      })
    },
  }
}
