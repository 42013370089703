import { initializeFullstory } from './initialize-fullstory'
import { injectFullstory } from './inject-fullstory'
import { isSelectedUser } from '../monitoring-utils'

export const FULLSTORY_LOCAL_KEYNAME = 'fullstory'
// Randomly select 4% of sessions. Fullstory account is 100k max sessions over 30 days.
// Yes, sessions != users, but we're going to see if it's a close approximation for how Fullstory calculates things.
const FULLSTORY_ACCEPTANCE_PROBABILITY = 0.04
export const FULLSTORY_LOCAL_INFO_DAYS_TO_LIVE = 30

export const shouldCaptureFullstory = () => {
  return isSelectedUser(
    FULLSTORY_LOCAL_KEYNAME,
    FULLSTORY_ACCEPTANCE_PROBABILITY,
    FULLSTORY_LOCAL_INFO_DAYS_TO_LIVE
  )
}

export function load() {
  if (process.env.FULLSTORY_API_KEY && shouldCaptureFullstory()) {
    injectFullstory()
    return ({ account, session }) => {
      if (session) {
        initializeFullstory(session, account)
      }
    }
  }
}
