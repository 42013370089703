import * as R from 'ramda'
/**
 * @typedef {import("../type-defs")}
 * @typedef {string} PendoApiKey
 */

/**
 * Identifies the current user with Fullstory.
 *
 * @param {Session} session
 * @param {Domain} domain
 * @param {Object} [options]
 * @param {Environment} [options.env]
 */
export function initializeFullstory(
  session,
  domain,
  { env = process.env.DEPLOY_ENV } = {}
) {
  const fsConfig = toFullstoryConfiguration({ domain, env, session })
  const fullstory = window[window['_fs_namespace']]

  if (fullstory && typeof fullstory.identify === 'function') {
    fullstory.identify(fsConfig.uid, fsConfig.userVars)
  }
}

/**
 * Creates a selector for determining if the specified product is enabled.
 * @param {ProductName} productName
 */
const isProductEnabled = (productName) =>
  R.pipe(
    R.pathOr([], ['domain', 'config', 'products']),
    R.any(
      R.allPass([
        R.prop('subscriptionType'),
        R.propEq('productName', productName),
      ])
    )
  )

/**
 * Migrates environment name to match Learn ('prod' -> 'production')
 * @param {Environment} env
 * @returns {Environment}
 */
const getEnv = (env) =>
  R.compose(R.replace(/\bprod\b/g, 'production'), R.toLower, env)

/**
 * @typedef {Object} FullstoryConfiguration
 * @property {string} uid
 * @property {Object} userVars
 * @property {string} userVars.accountId_str
 * @property {string} userVars.accountDomainId_str
 * @property {string} userVars.accountDomainName_str
 * @property {string} [userVars.accountDomainSubdomain_str]
 * @property {string} userVars.accountEnvironment_str
 * @property {string} userVars.accountRegion_str
 * @property {string} userVars.accountSalesforceId_str
 * @property {string} userVars.accountTenantDatabase_str
 * @property {string} [userVars.accountTenantSubdomain_str]
 * @property {AccountType} userVars.accountTenantType_str
 * @property {boolean} userVars.accountCareerEnabled_bool
 * @property {boolean} userVars.accountConnectEnabled_bool
 * @property {boolean} userVars.accountEngageEnabled_bool
 * @property {boolean} userVars.accountLearnEnabled_bool
 * @property {boolean} userVars.accountPerformEnabled_bool
 * @property {string} userVars.userLocalId_str
 * @property {boolean} userVars.userIsMasquerading_bool
 * @property {string} userVars.userLocale_str
 */

/**
 * Creates Fullstory configuration.
 *
 * @param {Object} options
 * @param {Session} options.session
 * @param {Domain} options.domain
 * @param {Environment} options.env
 * @returns {FullstoryConfiguration}
 */
export const toFullstoryConfiguration = (args) => {
  const config = R.applySpec({
    uid: R.compose(
      R.toLower,
      R.join('___'),
      R.juxt([
        R.path(['domain', 'account', 'database']),
        R.path(['domain', 'id']),
        getEnv(R.path(['env'])),
        R.path(['domain', 'regionCode']),
        R.path(['session', 'user', 'id']),
      ])
    ),

    // For details on how these vars need to be titled see:
    // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
    //
    userVars: {
      accountId_str: R.compose(
        R.toLower,
        R.join('___'),
        R.juxt([
          R.path(['domain', 'account', 'database']),
          R.path(['domain', 'id']),
          getEnv(R.path(['env'])),
          R.path(['domain', 'regionCode']),
        ])
      ),
      accountDomainId_str: R.path(['domain', 'id']),
      accountDomainName_str: R.compose(R.toLower, R.path(['domain', 'name'])),
      accountDomainSubdomain_str: R.compose(
        R.unless(R.isNil, R.toLower),
        R.path(['domain', 'host', 'subdomain'])
      ),
      accountEnvironment_str: getEnv(R.path(['env'])),
      accountRegion_str: R.compose(R.toLower, R.path(['domain', 'regionCode'])),
      accountSalesforceId_str: R.compose(
        R.toUpper,
        R.pathOr('NO_SALESFORCE_ID', [
          'domain',
          'account',
          'config',
          'salesforceId',
        ])
      ),
      accountTenantDatabase_str: R.path(['domain', 'account', 'database']),
      accountTenantSubdomain_str: R.compose(
        R.unless(R.isNil, R.toLower),
        R.path(['domain', 'account', 'host', 'subdomain'])
      ),
      accountTenantType_str: R.compose(
        R.toUpper,
        R.path(['domain', 'account', 'accountType'])
      ),
      accountCareerEnabled_bool: isProductEnabled('career'),
      accountConnectEnabled_bool: isProductEnabled('connect'),
      accountEngageEnabled_bool: isProductEnabled('engage'),
      accountLearnEnabled_bool: isProductEnabled('learn'),
      accountPerformEnabled_bool: isProductEnabled('perform'),
      userLocalId_str: R.path(['session', 'user', 'id']),
      userIsMasquerading_bool: R.pathOr(false, [
        'session',
        'meta',
        'isMasquerading',
      ]),
      userLocale_str: R.compose(
        R.toLower,
        R.path(['session', 'user', 'locale'])
      ),
      roles: R.pipe(
        R.pathOr([], ['session', 'user', 'roles']),
        R.map((r) => ({ [`userIs${r}_bool`]: true })),
        R.reduce((acc, curr) => {
          return Object.assign({}, acc, curr)
        }, {})
      ),
    },
  })(args)

  // A dirty hack necessary to get each of the roles aplied to the user
  // onto the nested userVars object. I couldn't figure out a way to
  // accomplish the samething purely within the R.applySpec().
  const returnObj = {
    ...config,
    userVars: {
      ...config.userVars,
      ...config.userVars.roles,
    },
  }
  delete returnObj.userVars.roles
  return returnObj
}
