import { fetchRemoteManifests } from './fetch-remote-manifests'

/**
 * Returns the manifest files to bundle with the app.
 *
 * @param {Object} params
 * @param {string} [params.cdnKey=process.env.CDN_KEY] - the cdnKey to use
 * @param {string[]} [params.ignoreApps=[]] - the IDs of apps to ignore
 * @returns {Promise<Manifest[]>} the bundled manifests
 */
export function collectManifests({
  cdnKey = process.env.CDN_KEY,
  ignoreApps = [],
} = {}) {
  return fetchRemoteManifests({
    cdnKey,
    ignoreApps,
    manifestUrl: process.env.MANIFEST_URL,
  })
}
