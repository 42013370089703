import { initializeDataDogRum } from './initizalize-datadog-rum'

export const canUseDatadogRum = process.env.DATADOG_RUM

export function load() {
  return ({ account, session, featureFlags }) => {
    if (!canUseDatadogRum) return
    initializeDataDogRum(account, session, featureFlags)
  }
}
