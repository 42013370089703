import * as R from 'ramda'

// These role GUIDs are not currently used, but keeping them here commented in case they are needed.
// const prodRoleCodes = [
//   '23431dac-3dc1-4800-93da-6ffe516701ab', // Author Enhanced
//   '07197ede-ee96-411e-8e6d-2cbde174ddf4', // Client Admin Essentials
//   '4c3773d9-d2ff-4ec7-83e8-bcec85580a16', // Client Admin Essentials
//   '010d1f7a-c7da-4a6c-ba1d-7a66ba47f3ec', // Client Admin Enhanced
//   '7eaa2c4f-6723-4ac6-9274-5c4be212a05a', // Client Admin Enhanced
//   'd2478ee0-26e2-4c5b-a24b-e22b7467b039', // Manager
//   '0a2efcec-b844-49c4-8b01-5b2cc6b97301', // Manager Author Enhanced
//   '122f7a27-7ef2-4e5d-b0a9-8b5c85bfa97f', // Manager Essentials
//   'de2e488b-5516-4a8f-99be-ff70ae694ab8', // Manager Enhanced
//   '98ae84c9-9046-4920-8ffc-f0d50f4e99ce', // Manager Purchaser Essentials
//   '2f3e7b6a-0522-40ed-a0d7-d8d7735d6fe0', // Manager Purchaser Essentials
//   '8a3f76d2-4522-44a0-8621-e815e1e2e283', // Manager Purchaser Enhanced
//   '53ed8340-6fdc-4b94-8f8e-f7aaf6ccea92', // Purchaser Essentials
//   'e377dd98-4d63-4c82-8fdb-a19a6f74e303', // Purchaser Enhanced
//   'account_admin', // Account Admin (
//   'admin', // Admin
//   'author', // Author
//   'it_admin', // IT Admin
//   'manager', // Manager
//   'observer', // Observer
// ]

// const stagingRoleCodes = [
// '784793e2-211f-4e58-a583-6899f0864050', // Author Enhanced
// '2212d4cc-d47b-488a-967b-d0396bb9adea', // Client Admin Essentials
// '2d689d43-0cd1-4ded-bee1-361a9c51bcbb', // Client Admin Enhanced
// 'd0ff25a2-dfab-4a70-8489-8c038b921b3c', // Manager Author Enhanced
// 'f133cee9-d3f7-41fe-b05f-fdbde391f5e4', // Manager Essentials
// '0889bf8d-4405-4a88-a71a-e7fc0c46aad2', // Manager Enhanced
// '1aadecde-043b-45b0-8a22-b1d627773d9f', // Manager Purchaser Essentials
// 'a6562bf6-9374-4ca2-bb50-49895f62a364', // Manager Purchaser Enhanced
// '613236be-5e89-4e1e-aa9e-5b82711b9eea', // Purchaser Essentials
// '518cdeeb-6325-4a48-9493-f2f2f84827bb', // Purchaser Enhanced
// 'account_admin', // Account Admin
// 'admin', // Admin
// 'author', // Author
// 'it_admin', // IT Admin
// 'manager', // Manager
// 'observer', // Observer
// ]

/**
 * @typedef {import("../type-defs")}
 */

/**
 * Initializes Pendo.
 *
 * @param {Session} session
 * @param {Domain} domain
 * @param {Object} [options]
 * @param {Environment} [options.env]
 */
export function initializePaychexPendo(
  session,
  domain,
  env = process.env.DEPLOY_ENV
) {
  if (hasNeededData({ env, session, domain })) {
    window.pendo.initialize(toPendoConfiguration({ domain, env, session }))
  }
}

/**
 * Determines if the necessary pieces of data for initializing Pendo are available.
 *
 * @param {Object} [options]
 * @param {Environment} [options.env]
 * @param {Session} [options.session]
 * @param {Domain} [options.domain]
 */
const hasNeededData = ({ env, session, domain }) => {
  return !!(
    session &&
    session.user &&
    domain &&
    domain.id &&
    domain.regionCode &&
    domain.account &&
    domain.account.id &&
    env
  )
}

const isAdmin = (userRoles) => {
  // We can determine whether a Paychex user is an admin if they have ANY role.
  return userRoles && userRoles.length > 0
}

const getPaychexUserId = (userUid) => {
  if (!userUid) return ''

  const uiTokens = userUid.split(':')

  return uiTokens.length > 1
    ? uiTokens[1].toUpperCase()
    : uiTokens[0].toUpperCase()
}

/**
 * @typedef {Object} PendoConfiguration
 * @property {Object} account
 * @property {string} account.id
 * @property {string} account.name
 * @property {string} account.environment
 * @property {string} account.region
 * @property {string} [account.tenantSubdomain]
 * @property {Object} visitor
 * @property {string} visitor.id
 * @property {string[]} visitor.roles
 * @property {string} visitor.userId
 * @property {string} visitor.domainId
 * @property {string} visitor.domainName
 * @property {string} [visitor.domainSubdomain]
 */

/**
 * Creates Pendo configuration.
 *
 * @param {Object} options
 * @param {Session} options.session
 * @param {Domain} options.domain
 * @param {Environment} options.env
 * @returns {PendoConfiguration}
 */
export const toPendoConfiguration = (args) => {
  args.usertype = isAdmin(args.session.user.roles) ? 'admin' : 'employee'
  args.paychexVisitorId = getPaychexUserId(args.session.user?.uid)

  const config = R.applySpec({
    account: {
      id: R.compose(
        R.toLower,
        R.join('___'),
        R.juxt([R.path(['domain', 'host', 'subdomain'])])
      ),
      name: R.compose(
        R.unless(R.isNil, R.toLower),
        R.path(['domain', 'host', 'subdomain'])
      ),
    },
    visitor: {
      id: R.path(['paychexVisitorId']),
      usertype: R.path(['usertype']),
    },
  })(args)

  return config
}
