import { load as loadFullStory } from './fullstory'
import { load as loadPendo } from './pendo'
import { load as loadPingdom } from './pingdom'
import { load as loadGoogleAnalytics } from './google-analytics'
import { load as loadDatadogRum } from './datadog'

// During transition of ".suite" domains to domain without "suite",
// we need to filter out the "suite" hits so users don't get two chances at being selected for Pendo and Full Story.
export function isSuiteSubdomain() {
  return window?.location?.href && window.location.href.indexOf('.suite') > -1
}

export function loadMonitoring(params = {}) {
  const { featureFlags = {} } = params
  const enableAnalytics = !!featureFlags['global_firebase']
  const enableDatadogRum = !!featureFlags['datadog_rum']

  const proms = [
    !isSuiteSubdomain() && enableAnalytics && loadFullStory(),
    enableAnalytics && loadGoogleAnalytics(),
    enableDatadogRum && loadDatadogRum(),
    loadPendo(),
    loadPingdom(),
  ].filter(Boolean)

  return Promise.all(proms).then((monitors) => {
    const initializers = monitors
      .filter(Boolean)
      .map((monitor) => monitor(params))

    return Promise.all(initializers).then((results) => {
      const allInitResults = results.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      )
      // Save this to browser window object for exposure to micro front ends (through tapestry sdk).
      window['BRIDGE_PLATFORM_SERVICES'] = allInitResults

      return allInitResults
    })
  })
}
