import { datadogRum } from '@datadog/browser-rum'

export function initializeDataDogRum(account, session, featureFlags = {}) {
  const increaseSampleRate = !!featureFlags['dd_rum_sample_rate_100']

  datadogRum.init({
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_RUM,
    site: 'datadoghq.com',
    service: 'tapestry',
    env: process.env.DEPLOY_ENV,
    sessionSampleRate: increaseSampleRate ? 100 : 10,
    sessionReplaySampleRate: 1,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  if (!account || !session) return
  datadogRum.setUser({
    userId: session?.user?.id,
    userName: session?.user?.uid,
    userEmail: session?.user?.email,
    tenant: account?.account?.name,
    subaccountName: account?.name,
    products: account?.account?.config?.products,
    accountId: account?.account?.id,
    accountType: account?.account?.accountType,
    region: account?.regionCode,
    learnHost: account?.learnHost,
  })
  datadogRum.startSessionReplayRecording()
}
