import { loadMonitoring } from '../monitoring'

export function beforeStart() {
  return Promise.resolve().then(() => (params) => {
    window.DEPLOY_ENV = process.env.DEPLOY_ENV
    return loadMonitoring(params)
  })
}

export function onError(err, { windowRef = window } = {}) {
  console.error(err)

  // Transient errors shouldn't display the boot error window.
  if (err.isTransient !== true && windowRef.location.pathname !== '/503.html') {
    windowRef.location.replace(
      '/503.html?origin=' +
        encodeURIComponent(
          windowRef.location.pathname +
            windowRef.location.search +
            windowRef.location.hash
        )
    )
  }
}
