import * as R from 'ramda'
import {
  GA_API_KEY,
  GA_PROJECT_ID,
  GA_MESSAGING_SENDER_ID,
  GA_APP_ID,
  GA_MEASUREMENT_ID,
} from './ga.config'

export function getConfig() {
  return {
    apiKey: GA_API_KEY,
    authDomain: `${GA_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${GA_PROJECT_ID}.firebaseio.com`,
    projectId: GA_PROJECT_ID,
    storageBucket: `${GA_PROJECT_ID}.appspot.com`,
    messagingSenderId: GA_MESSAGING_SENDER_ID,
    appId: `1:${GA_MESSAGING_SENDER_ID}:web:${GA_APP_ID}`,
    measurementId: GA_MEASUREMENT_ID,
  }
}

const isProduct = (productName) =>
  R.allPass([R.prop('subscriptionType'), R.propEq('productName', productName)])

/**
 * Creates a selector for determining if the specified product is enabled.
 * @param {ProductName} productName
 */
const isProductEnabled = (productName) =>
  R.pipe(
    R.pathOr([], ['domain', 'config', 'products']),
    R.any(isProduct(productName))
  )

const isLearnOnly = R.pipe(
  R.pathOr([], ['domain', 'config', 'products']),
  R.allPass([(products) => products.length === 1, R.all(isProduct('learn'))])
)

const firstValueFromPaths = (paths) =>
  R.pipe(
    R.paths(paths),
    R.append(''), // Ensures there's at least one element after filter
    R.filter(Boolean),
    R.head
  )

export function toUserId(session) {
  return R.pathOr('', ['user', 'uuid'], session)
}

export function toUserConfig({ domain, session }) {
  return R.applySpec({
    /*
      learn_only: True if learn is in products, and there is no other product,
      regardless of subscription_type
    */
    learn_only: isLearnOnly,
    /*
      has_perform: True if perform is among products, regardless of subscription_type.
    */
    has_perform: isProductEnabled('perform'),
    /*
      has_connect: True if connect is among products, regardless of subscription_type.
    */
    has_connect: isProductEnabled('connect'),
    /*
      has_career: True if career is among products, regardless of subscription_type.
    */
    has_career: isProductEnabled('career'),
    /*
      has_engage: True if engage is among products, regardless of subscription_type.
    */
    has_engage: isProductEnabled('engage'),
    /*
      is_manager: true if session.user.is_manager is true
    */
    is_manager: R.path(['session', 'user', 'isManager']),
    /*
      is_admin: True if at least one of these is among the roles:
        account_admin, admin, it_admin
    */
    is_admin: R.compose(
      R.anyPass([
        R.includes('account_admin'),
        R.includes('admin'),
        R.includes('it_admin'),
      ]),
      R.pathOr([], ['session', 'user', 'roles'])
    ),
    /*
      is_author: True if "author" is among the roles.
    */
    is_author: R.compose(
      R.includes('author'),
      R.pathOr([], ['session', 'user', 'roles'])
    ),
    subdomain: R.pathOr('', ['domain', 'host', 'subdomain']),
    vanity_domain_active: R.pathSatisfies(
      (val) => val === 'vanity_domain_active',
      ['domain', 'host', 'vanityDomainState']
    ),
    /*
      account_name: uses account.host.subdomain, or host.subdomain if
        the first does not exist.
    */
    account_name: firstValueFromPaths([
      ['domain', 'account', 'host', 'subdomain'],
      ['domain', 'host', 'subdomain'],
    ]),
    /*
      account_display_name: uses account.name, or name if
        the first does not exist.
    */
    account_display_name: firstValueFromPaths([
      ['domain', 'account', 'name'],
      ['domain', 'name'],
    ]),
    region: R.path(['domain', 'regionCode']),
    account_type: R.path(['domain', 'account', 'accountType']),
    is_masquerading: R.path(['session', 'meta', 'isMasquerading']),
    locale: R.path(['session', 'user', 'locale']),
  })({ domain, session })
}
