export function injectPendoStub() {
  window.pendo = {
    initialize: function (configuration) {
      console.groupCollapsed('🐼 Pendo Initialization')
      console.dir(configuration)
      console.groupEnd()
    },
  }
}

/**
 * Adds the Pendo startup logic to the page, when PENDO_API_KEY is defined.
 * Otherwise, adds a console debug block.
 */
export function injectPendo() {
  if (process.env.PENDO_API_KEY) {
    ;(function (apiKey) {
      ;(function (p, e, n, d, o) {
        var v, w, x, y, z
        o = p[d] = p[d] || {}
        o._q = []
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad']
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                )
              }
          })(v[w])
        y = e.createElement(n)
        y.async = !0
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
        z = e.getElementsByTagName(n)[0]
        z.parentNode.insertBefore(y, z)
      })(window, document, 'script', 'pendo')
    })(process.env.PENDO_API_KEY)
  } else {
    injectPendoStub()
  }
}
