import { apiClient } from '../api-client'

export async function fetchFeatureFlags({ baseUrl = '' } = {}) {
  try {
    const { feature_flags } = await apiClient(`${baseUrl}/api/feature_flags`)
    return feature_flags
  } catch (error) {
    throw new Error(
      `Unable to fetch the feature flags from Bridge Learn. Please verify it is enabled as a platform module.\n\nSource error:\n\n\t${error}`
    )
  }
}

export function isFeatureEnabled(
  features,
  feature,
  { defaultValue = false } = {}
) {
  const featureOverride = localStorage && localStorage.getItem(feature)
  if (featureOverride) {
    return featureOverride === 'true'
  }

  if (!features || typeof features !== 'object') {
    console.warn('Missing or malformed feature flag set.')
    return defaultValue
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!features.hasOwnProperty(feature)) {
    console.warn(`Unrecognized feature flag "${feature}".`)
    return defaultValue
  }

  return features[feature] === true
}
