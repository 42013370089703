import * as R from 'ramda'
import { prependCdn } from './stringUtils'

/**
 * Convert an application spec, along with variables, to a list of resources to load.
 * @param {Object} [options={}] - options
 * @param {string[]} [options.assets=[]] - the assets
 * @param {string} [options.cdn=''] - the cdn string to prepend to each entry
 * @param {string|string[]} [options.entry=[]] - the entry option
 * @param {variables} [options.variables = {}] - template variables
 * @returns {string[]} the resources to load, in order
 */
export function getEntryResourceUrls({
  assets = [],
  cdn = '',
  entry = [],
  variables = {},
} = {}) {
  return getEntryAssetUrls({ assets, cdn, variables }).concat(
    getEntryScriptUrls({ cdn, entry, variables })
  )
}

/**
 * Converts an application assets value to a collection of styles to load.
 *
 * @param {Object} [options={}] - options
 * @param {string[]} [options.assets=[]] - the assets
 * @param {string} [options.cdn=''] - the cdn string to prepend to each entry
 * @param {variables} [options.variables = {}] - template variables
 * @returns {string[]} the styles to load, in order
 */
export function getEntryAssetUrls({
  assets = [],
  cdn = '',
  variables = {},
} = {}) {
  return R.pipe(
    R.filter(isStylesheet),
    R.pluck('href'),
    transformUrls({ cdn, variables })
  )(assets)
}

/**
 * Converts an application entry value to a collection of scripts to load.
 * (curried)
 *
 * @param {Object} [options={}] - options
 * @param {string} [options.cdn=''] - the cdn string to prepend to each entry
 * @param {string|string[]} [options.entry=[]] - the entry option
 * @param {variables} [options.variables = {}] - template variables
 * @returns {string[]} the scripts to load, in order
 */
export function getEntryScriptUrls({
  cdn = '',
  entry = [],
  variables = {},
} = {}) {
  return R.pipe(toArray, R.flatten, transformUrls({ cdn, variables }))(entry)
}

/**
 * Modifies an array of resources using the following transforms:
 * - merges resource templates with the provided variables
 * - flattens the result (in case some variables introduced nested arrays)
 * - removes invalid resource urls
 * - ensures each url begins with the specified cdn value
 *
 * @param {Object} options
 * @param {string} options.cdn - the cdn value
 * @param {Object} options.variables - the available variables for template merging
 * @param {string[]} entries - a list of resources to transform
 * @returns {string[]} an array of resource URLs
 */
const transformUrls = ({ cdn, variables }) =>
  R.pipe(
    R.map(createResourceTemplate(variables)),
    R.flatten,
    R.filter(urlContainsTemplate),
    R.map(prependCdn(cdn))
  )

/**
 * Wraps the value in an array if it's not already an array.
 *
 * @param {string|string[]} val - the value to potentially wrap
 * @returns {string[]}
 */
const toArray = (val) => (Array.isArray(val) ? val : [val])

const CONTAINS_TEMPLATE = /\{\{\s.*?\s\}\}/
const TEMPLATE_PATTERN = /^\{\{\s*(.+?)\s*\}\}$/

/**
 * Creates a template for merging values into a template string.
 * If a value if a string value, the original value is returned
 * (curried)
 *
 * @param {Object} params - the variables available for merging with the template
 * @param {string} value - either a template string, or a string literal
 * @returns {string|Object} the original string, or a resolved template value
 */
const createResourceTemplate = (params) => (value) => {
  const match = value.match(TEMPLATE_PATTERN)
  return match ? R.pathOr(value, match[1].split('.'))(params) : value
}

/**
 * Determines whether this string is a template.
 *
 * @param {string} value - the string to test whether it's a template
 * @returns {boolean}
 */
const containsTemplate = (value) => CONTAINS_TEMPLATE.test(value)

/**
 * Returns true when the url contains no template.
 * Side-effect: logs a warning when the url contains the template.
 *
 * @param {string} url - the url to test
 * @returns {boolean}
 */
const urlContainsTemplate = (url) => {
  if (containsTemplate(url)) {
    console.warn(`Invalid resource url: "${url}"`)
    return false
  }
  return true
}

/**
 * Returns true when the entry is a stylesheet.
 * Side-effect: logs a warning when the url contains the template.
 *
 * @param {Object} entry - the asset entry
 * @param {string} entry.type - the asset type
 * @returns {boolean}
 */
const isStylesheet = ({ type }) => {
  if (type === 'stylesheet') {
    return true
  }
  console.warn(`Unrecognized asset type: "${type}"`)
  return false
}
