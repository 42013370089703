/**
 * Returns a path with the CDN value prepended.
 * (curry-able)
 * The path will be unchanged when the path already contains the CDN value.
 *
 * @param {string} [cdn=''] - the CDN value
 * @param {string} path - the path to transform
 * @returns {string} the path with the CDN prepended
 */
export const prependCdn =
  (cdn = '') =>
  (path) =>
    path.startsWith(cdn) ? path : cdn + path
