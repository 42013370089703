import axios from 'axios'
import { camelizeKeys } from '@get-bridge/bridge-string-utils'
import { CSRF_HEADER_NAME } from '@get-bridge/tapestry-sdk'
import { AstralSessionAdapter } from './astral-session-adapter'
import { isUnauthenticatedRoute, shouldSessionBeRefreshed } from './utils'

export const LOGIN_URL = '/sso/authmonger/login'
export const REFRESH_URL = '/sso/authmonger/refresh'
export const USER_URL = '/sso/users/self'

// TODO Determine whether to keep this abstraction separated from the adapter implementation
// or flatten into one handler.
export class SessionHandler {
  constructor(windowReference = window) {
    this.window = windowReference
    this.sessionAdapter = new AstralSessionAdapter({
      localStorage: this.window.localStorage,
      reissueHandler: this.reissueAuthentication.bind(this),
    })
  }

  /**
   * Retrieve the logged-in user information.
   *
   * @type {(): Promise.<Object, Axios::Error>}
   */
  async fetch() {
    try {
      const { data } = await axios.get(USER_URL, {
        params: {
          // append an unused timestamp param to bust IE's aggressive caching
          timestamp: Date.now(),
        },
      })
      this.sessionAdapter.save()
      return camelizeKeys(data)
    } catch (e) {
      if (isUnauthenticatedRoute(this.window.location.pathname)) {
        return null
      }
      const { data } = await this.handleUnauthorized(e)
      this.sessionAdapter.save()
      return camelizeKeys(data)
    }
  }

  /**
   * Grab the current CSRF token to stamp outbound XHRs with. The header is
   * X-SSO-CSRF.
   *
   * This is exposed by the SDK for applications to use.
   *
   * @type {(): ?String}
   */
  getCsrfToken() {
    return this.sessionAdapter.getCsrfToken()
  }

  /**
   * Try to refresh the session if it has expired. Otherwise, redirect to the
   * login page.
   *
   * @type {(): Promise.<void>}
   */
  reissueAuthentication() {
    if (!this.sessionAdapter.canRefresh()) {
      return this.redirectToLogin({ response: { status: 401 } })
    }

    const params = this.sessionAdapter.getRefreshParameters()
    const headers = this.getRefreshHeaders()

    return axios
      .post(REFRESH_URL, params, { headers })
      .catch((e) => this.redirectToLogin(e))
      .then((response) => {
        this.sessionAdapter.refresh()
        return response
      })
  }

  //                            PRIVATE APIS
  // ---------------------------------------------------------------------------
  //
  //                       ____________________
  //                       /                    \
  //                       |     I CAN DO IT    |
  //                       |                    |
  //                       \____________________/
  //                                !  !
  //                                !  !
  //                                L_ !
  //                               / _)!
  //                              / /__L
  //                        _____/ (____)
  //                               (____)
  //                        _____  (____)
  //                             \_(____)
  //                                !  !
  //                                !  !
  //                                \__/

  /**
   * Contruct header for making refresh request.
   *
   * @type {(): ?Object}
   */
  getRefreshHeaders() {
    return {
      [CSRF_HEADER_NAME]: this.getCsrfToken(),
    }
  }

  // If the error indicates that a session has expired and may potentially be
  // refreshed, try to refresh it. If that works, then also retry the request
  // for user information retrieval.
  //
  // (Axios::Error): ?Promise.<Object, Axios::Error>
  //
  // @throws When the failure is not a "session needs refreshing" one.
  // @see    #reissueAuthentication
  // @see    #fetch
  // @impure Calls everything and more still
  handleUnauthorized(error) {
    if (shouldSessionBeRefreshed(error, { windowRef: this.window })) {
      return this.reissueAuthentication().then(() => axios(error.config))
    }

    throw new Error(
      `Unable to fetch the session.\n\nSource error:\n\n\t${error}`
    )
  }

  // (Error): Promise.<Error>
  //
  // @impure Calls out to location.replace() causing the browser /byebye
  redirectToLogin(error) {
    error.isTransient = true
    const { location } = this.window
    const url = encodeURIComponent(
      location.pathname + location.search + location.hash
    )
    this.window.location.replace(`${LOGIN_URL}?location=${url}`)
    return Promise.reject(error)
  }
}
