import { activatePlatform, CSRF_HEADER_NAME } from '@get-bridge/tapestry-sdk'
import * as R from 'ramda'
import axios from 'axios'
import {
  createEmitter,
  subscribeToHCMEvent,
  subscribeToUnderlineEvent,
} from './event-subscriptions'
import { getDeclaredApplications, getDeclaredPlugins } from './Manifest'
import {
  getBridgeAppsNode,
  prepareDOMForApplications,
  prepareMainDOMNode,
  setDocumentLocale,
} from './UI'
import {
  getAvailableAppModulesFrom,
  morphApplication,
} from './SingleSpaApplication'
import {
  PlatformRoutingEvent,
  RelayedRoutingEvent,
  BootstrapQueueInstance,
  create as createPlatform,
  run as runPlatform,
  getActiveApps,
} from './Platform'
import { collectServices } from './services'
import {
  createHolder,
  isHCMDisabledFn,
  isHighContrastEnabled,
  isUnderlineLinksEnabled,
} from './utils'

const noop = () => {}
const MAIN_SELECTOR = '[role="main"]'

const setCustomFavicon = (customFaviconUrl) => {
  const setFavicon = (element) => {
    if (element && typeof customFaviconUrl === 'string') {
      element.href = customFaviconUrl
      if (customFaviconUrl.toLowerCase().endsWith('.ico')) {
        element.type = 'image/x-icon'
      }
    }
  }

  const elementIds = [
    'link_favicon_32',
    'link_favicon_16',
    'link_mask_icon',
    'link_shortcut_icon',
    'link_apple_touch_icon',
  ]
  elementIds.forEach((id) => {
    setFavicon(document.getElementById(id))
  })
}

async function main({
  featureFlags: featureFlagsParam,
  featureFlagsDefault: featureFlagsParamDefault,
  onInit,
  onLoad,
  rootDOMNode,
  services: overriddenServices,
}) {
  const services = collectServices(overriddenServices, {
    featureFlags: featureFlagsParam,
    featureFlagsDefault: featureFlagsParamDefault,
  })

  axios.interceptors.request.use((config) => {
    const csrfToken = services.session.getCsrfToken()
    if (csrfToken) {
      config.headers[CSRF_HEADER_NAME] = csrfToken
    }
    return config
  })

  const [session, featureFlags, manifests] = await Promise.all([
    services.session.fetch(),
    services.featureFlags.fetch(),
    services.getManifests(),
  ])

  if (session && session.user) {
    setDocumentLocale(session.user.locale)
  }

  const account = await services.fetchAccount(session)
  const customProps = onInit
    ? await onInit({ account, session, featureFlags })
    : {}

  const applications = getDeclaredApplications(manifests)

  const exports = {}

  const ssApps = applications.map(
    morphApplication({
      domNodes: prepareDOMForApplications(applications, {
        mainNode: prepareMainDOMNode({
          parentNode: rootDOMNode,
        }),
        parentNode: rootDOMNode,
      }),
      exports,
      featureFlags,
      session,
      account,
      applications,
      hosts: services.getHostsFor(account),
      customProps,
    })
  )

  const customFavicon =
    account && account.config && account.config.customFavicon
  if (customFavicon) {
    setCustomFavicon(customFavicon)
  }

  // Temporary code due to font update - BRD-13613
  const fontUpdateEnabled = services.featureFlags.isFeatureEnabled(
    'bridge-2023-font-update-phase-1'
  )

  if (fontUpdateEnabled) {
    document.body && document.body.setAttribute('data-font-update', '')
  } else {
    document.body && document.body.removeAttribute('data-font-update')
  }

  const fontHeadersEnabled = services.featureFlags.isFeatureEnabled(
    'bridge-font-update-phase-2'
  )

  if (fontHeadersEnabled) {
    document.body && document.body.setAttribute('data-font-update-header', '')
  } else {
    document.body && document.body.removeAttribute('data-font-update-header')
  }

  return {
    exports,
    onLoad,
    session,
    plugins: getDeclaredPlugins(manifests),
    services,
    ssApps,
  }
}

function run({ exports, onLoad, session, plugins, services, ssApps }) {
  const getAvailableAppModules = getAvailableAppModulesFrom(ssApps)
  const onLoadOnce = R.once(onLoad || noop)
  const platform = createPlatform(ssApps)
  const sessionValue = createHolder(session)

  const broadcastLocationChange = (nextState, relevantApps) => {
    getAvailableAppModules(relevantApps)
      .filter((app) => typeof app.locationDidChange === 'function')
      .forEach((app) => {
        if (app.version >= 2) {
          app.locationDidChange(nextState)
        } else {
          const { location } = nextState
          app.locationDidChange(location)
        }
      })
  }

  const broadcastIdentityChange = (nextSession, nextState, relevantApps) => {
    getAvailableAppModules(relevantApps)
      .filter((app) => typeof app.identityDidChange === 'function')
      .forEach((app) => {
        app.identityDidChange(nextSession, nextState)
      })
  }

  const emitLoaded = (initial, actual, failed) => {
    const loaded = R.concat(actual, failed)

    if (R.intersection(initial, loaded).length === initial.length) {
      try {
        onLoadOnce()
        BootstrapQueueInstance.invokeQueued()
      } catch (e) {
        console.error(e)
      }
    }
  }

  const { emitter, unsubscribe } = createEmitter()
  const { emit: setHighContrastMode } = subscribeToHCMEvent(
    emitter,
    isHCMDisabledFn(plugins, () => getActiveApps(platform))
  )
  const { emit: setUnderlineLinks } = subscribeToUnderlineEvent(emitter)

  const { initialActiveApps, stop: stopPlatform } = runPlatform(platform, {
    // TODO: cleanup app states like global injections etc.
    // TODO: i think we want to also cancel timers / XHRs here as much as we can
    appDidChange: (nextState) => {
      const { activeApps, brokenApps } = nextState

      emitLoaded(initialActiveApps, activeApps, brokenApps)
      setHighContrastMode(isHighContrastEnabled(sessionValue.get()))
      setUnderlineLinks(isUnderlineLinksEnabled(sessionValue.get()))

      getAvailableAppModules(activeApps)
        .filter((app) => typeof app.appDidChange === 'function')
        .forEach((app) => {
          app.appDidChange(nextState)
        })
    },

    /**
     * @param nextState the platform state
     * @param statusData
     * @param statusData.statusCode status code related to the status page (403, 404, etc)
     */
    statusDidChange: (nextState, statusData) => {
      const { activeApps } = nextState

      getAvailableAppModules(activeApps)
        .filter((app) => typeof app.statusDidChange === 'function')
        .forEach((app) => {
          app.statusDidChange(statusData)
        })
    },

    locationDidChange: (nextState, eventType, eventSource) => {
      const { activeApps } = nextState

      const appsNode = getBridgeAppsNode()
      if (appsNode) {
        if (appsNode.querySelector(MAIN_SELECTOR)) {
          // a child app has already declared its own Main region.
          // remove role=main from the platform.
          appsNode.removeAttribute('role')
          appsNode.removeAttribute('tabindex')
        } else {
          appsNode.setAttribute('role', 'main')
          appsNode.setAttribute('tabindex', '-1')
        }
      }

      if (eventType === RelayedRoutingEvent) {
        broadcastLocationChange(
          nextState,
          // don't emit the event to the source application!
          R.without([eventSource], activeApps)
        )
      } else if (eventType === PlatformRoutingEvent) {
        broadcastLocationChange(nextState, activeApps)
      }
    },

    identityDidChange: (nextSession, nextState) => {
      sessionValue.set(nextSession)
      const { activeApps } = nextState
      broadcastIdentityChange(nextSession, nextState, activeApps)
    },

    focusMainContentNode: () => {
      const selectors = [`${MAIN_SELECTOR} h1`, MAIN_SELECTOR, 'h1']
      const mainContent = selectors.reduce(
        (firstFoundNode, selector) =>
          firstFoundNode || document.querySelector(selector),
        null
      )
      if (mainContent) {
        // ensures the main content is focusable
        const tabindex = 'tabindex'
        const prevTabIndex = mainContent.getAttribute(tabindex)
        mainContent.setAttribute(tabindex, '-1')
        mainContent.focus()
        mainContent.setAttribute(tabindex, prevTabIndex)
      } else
        console.warn(
          'Tried to Skip to Content, but did not find either a role=main landmark or an H1 anywhere in the DOM!'
        )
    },

    fetchSession() {
      return services.session.fetch()
    },
  })

  activatePlatform({
    emitter,
    exports,
    // we're exposing a function instead of a reference to the plain feature
    // objects because i'm assuming the object is subject to change throughout
    // the session so this alleviates the need to re-interact with the SDK upon
    // such changes
    isFeatureEnabled(feature) {
      return services.featureFlags.isFeatureEnabled(feature)
    },
    isHighContrastEnabled() {
      return isHighContrastEnabled(sessionValue.get())
    },
    servicePlugins: plugins,
    reissueAuthentication: () =>
      services.session.reissueAuthentication().then(() => null),
  })

  return {
    enabledApplications: R.pluck('id', ssApps),
    initialApplications: initialActiveApps,
    platform,
    stop() {
      unsubscribe()
      return stopPlatform()
    },
  }
}

const start = (params) => main(params).then(run)

export { main, run, start }
