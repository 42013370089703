/* eslint-disable no-use-before-define */
import * as R from 'ramda'
import loadScript from 'loadjs'
import { prependCdn } from './stringUtils'

export class AssetLoadError extends Error {
  constructor(message = [], ...args) {
    super('Failed to load assets', ...args)
    this.assets = message
  }
}

/**
 * Load the variables for the specified URL.
 * Previous requests are cached by the URL, to prevent multiple requests to the same location.
 *
 * @param {string} url - the request URL
 * @returns the promise with the resolved data
 */
const variableFetcher = R.memoizeWith(R.identity, (url) =>
  window
    .fetch(url)
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return response
      }
      const error = new Error(response.statusText)
      error.response = response
      throw error
    })
    .then((response) => {
      const header = response.headers.get('content-type')
      const [contentType] = header ? header.split(';') : ['']
      return contentType.endsWith('json') ? response.json() : response.text()
    })
)

/**
 * Load the application variables using a relative or
 * absolute path (if CDN is provided).
 *
 * @param {object} param - an app definition
 * @param {object} [param.id] - app id
 * @param {object} [param.variables] - collection of app defined variables to load
 * @param {string} [param.cdn] - An optional CDN to load the variables from
 */
export const loadApplicationVariables = ({ id, variables, cdn }) => {
  if (Array.isArray(variables)) {
    return Promise.all(
      variables.map(({ format, href, key }) => {
        if (format === 'text') {
          return variableFetcher(prependCdn(cdn)(href))
            .then((data) => ({ [key]: data }))
            .catch(() => {
              console.warn(
                `Unable to fetch application manifest for '${id}'. Is this application running?`
              )
              return Promise.resolve({})
            })
        }

        return Promise.reject(
          new Error(`Unrecognized variable format "${format}"`)
        )
      })
    ).then(R.mergeAll)
  }

  return Promise.resolve({})
}

/**
 * The default loader implementation.
 * Fetches scripts in parallel, but applies them in array order.
 *
 * @param {string[]} toLoad - the list of scripts to load, in order.
 */
const scriptLoader = (toLoad) =>
  loadScript(toLoad, { async: false, returnPromise: true })

/**
 *
 * @param {string[]} [scripts=[]] - a list of scripts to load.
 * @param {Object} options
 * @param {Function} [options.loader] - the loader to use for loading scripts (used for testing)
 */
export function loadScriptsInOrder(
  scripts = [],
  { loader = scriptLoader } = {}
) {
  return scripts.length === 0
    ? Promise.resolve()
    : loader(scripts).catch((depsNotFound) => {
        throw new AssetLoadError(depsNotFound)
      })
}
