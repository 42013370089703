import { fetchAccount } from './account'
import { buildHosts } from './build-hosts'
import { createFeatureFlagService } from './feature-flags'
import { collectManifests } from './manifests'
import { SessionHandler } from './session'

export function collectServices(
  overrides = {},
  { featureFlags, featureFlagsDefault } = {}
) {
  return {
    featureFlags: createFeatureFlagService({
      featureFlags,
      featureFlagsDefault,
    }),
    fetchAccount,
    getHostsFor: buildHosts({
      career: process.env.BRIDGE_CAREER_HOST,
      learn: process.env.BRIDGE_LEARN_HOST,
      perform: process.env.BRIDGE_PERFORM_HOST,
    }),
    getManifests: collectManifests,
    session: new SessionHandler(),
    ...overrides,
  }
}
