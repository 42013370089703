import * as singleSpa from 'single-spa'
import * as R from 'ramda'
import invariant from 'invariant'
import DisabledAppsHandler from './DisabledAppsHandler'
import BootstrapQueue from './BootstrapQueue'
import { traceMessageOnError } from './consoleUtils'

export const PlatformRoutingEvent = {}
export const ApplicationRoutingEvent = {}
export const RelayedRoutingEvent = {}
export const BootstrapQueueInstance = new BootstrapQueue()

export const navigate = (platform, path) => platform.navigate(path)

/**
 * Initialize the Single-Spa platform and register applications.
 *
 * @param {Array.<SingleSpaApplication>} ssApps
 */
export const create = (ssApps) => {
  // We need to distinguish between changes done to location by ourselves like
  // through the global nav and those done by the applications themselves using
  // their routing implementations. In the case of the former, we will notify
  // applications of that event.
  //
  // TODO: we may want to consider passing a custom history instance to each
  // application but this only works under the assumption that applications do
  // not access the global window.history.location (which I *think* bridge-learn
  // currently does but talent seems not to which is nice) - that way this
  // implementation can be made fully reliable
  let lastRoutingEventOriginatedFromSelf = false

  let currentPathname = null
  let previousPathname = null

  const getPreviousPathname = () => previousPathname
  const getCurrentPathname = () =>
    window.location.pathname + window.location.search

  ssApps.forEach(({ id, isActive, loadApplication }) => {
    singleSpa.registerApplication(id, loadApplication, isActive, {
      getPreviousPathname,
      getCurrentPathname,
    })
  })

  return {
    /**
     * Change the location to the specified path.
     *
     * Calls to this function have the potential to activate or deactivate
     * applications and they will always emit a locationDidChange event
     * unless there was no change to the location (paths are identical.)
     *
     * @param {String} path
     */
    navigate: (path) => {
      lastRoutingEventOriginatedFromSelf = true
      singleSpa.navigateToUrl(path)
    },

    _appIds: ssApps.map(R.prop('id')),
    _initialActiveAppIds: ssApps
      .filter(({ isActive }) => isActive(window.location))
      .map(R.prop('id')),

    /** @private */
    _getState() {
      return {
        activeApps: singleSpa.getMountedApps(),
        brokenApps: ssApps
          .map(R.prop('id'))
          .filter(
            (appName) =>
              singleSpa.getAppStatus(appName) === 'SKIP_BECAUSE_BROKEN'
          ),
        location: window.location,
        previousPathname,
      }
    },

    updatePreviousPathname: (nextPathname) => {
      // occasionally fires twice, do nothing if currentPathname was already set
      if (nextPathname !== currentPathname) {
        previousPathname = currentPathname
        currentPathname = nextPathname
      }
    },

    /** @private */
    _consumeRoutingEvent() {
      if (lastRoutingEventOriginatedFromSelf) {
        lastRoutingEventOriginatedFromSelf = false
      }
    },

    /** @private */
    _getRoutingEventType() {
      return lastRoutingEventOriginatedFromSelf
        ? PlatformRoutingEvent
        : ApplicationRoutingEvent
    },
  }
}

/**
 * Start listening to location-related events.
 *
 * @param {PlatformAPI} platform
 * @param {Object} spec
 * @param {(Platform~State): void} spec.appDidChange
 *        Callback to invoke when an application is activated or deactivated.
 *
 * @param  {
 *           (
 *             Platform~State,
 *             Union.<PlatformRoutingEvent | ApplicationRoutingEvent>
 *           ): void
 *         } spec.locationDidChange
 *         Callback to invoke when the location changes.
 *         The second parameter can be used to tell the source of the change
 *         whether it has come from the platform itself (e.g. global nav) or
 *         from one of the active applications.
 *
 * @typedef {Platform~State}
 *          A construct that encapsulates the current state of the platform.
 *
 * @property {Array.<String>} activeApps
 *           The list of active applications IDs.
 *
 * @property {Location} location
 *           The current location.
 */
export const run = (
  platform,
  {
    appDidChange,
    statusDidChange,
    locationDidChange,
    identityDidChange,
    focusMainContentNode,
    fetchSession,
  }
) => {
  const { runWhenReady } = BootstrapQueueInstance
  const emitAppDidChange = () => {
    appDidChange(platform._getState())
  }

  const emitStatusDidChange = (e) =>
    runWhenReady(
      traceMessageOnError('changeAppStatus')(() => {
        const { source, statusCode } = e.data || {}

        invariant(
          typeof statusCode === 'number',
          'Status change broadcast event must contain a numeric statusCode.'
        )

        invariant(
          typeof source === 'string',
          'Status change broadcast event must contain an identifier for the ' +
            'application that it originated from.'
        )

        DisabledAppsHandler.disableApps(
          [source, 'bridge-nav'],
          'until-app-navigation'
        )
        statusDidChange(platform._getState(), e.data)
      })
    )

  const emitLocationDidChange = () => {
    locationDidChange(platform._getState(), platform._getRoutingEventType())

    platform._consumeRoutingEvent()
  }

  const relayLocationChangeFromApplication = (e) => {
    invariant(
      e && e.data && typeof e.data.source === 'string',
      'Location change broadcast event must contain an identifier for the ' +
        'application that it originated from.'
    )

    locationDidChange(platform._getState(), RelayedRoutingEvent, e.data.source)
  }

  const navigateByApplicationRequest = (e) =>
    runWhenReady(
      traceMessageOnError('navigate')(() => {
        DisabledAppsHandler.emptyDisableList('until-app-navigation')
        navigate(platform, e.data.pathname)
      })
    )

  const relayProfileChangeFromApplication = () =>
    runWhenReady(
      traceMessageOnError('relayProfileChange')(() => {
        fetchSession().then((nextSession) => {
          identityDidChange(nextSession, platform._getState())
        })
      })
    )

  const beforeRoutingEvent = () => {
    platform.updatePreviousPathname(
      window.location.pathname + window.location.search
    )
  }

  const queuedFocusMainContentNode = () =>
    runWhenReady(
      traceMessageOnError('focusMainContentNode')(focusMainContentNode)
    )

  window.addEventListener('single-spa:app-change', emitAppDidChange)
  window.addEventListener('single-spa:before-routing-event', beforeRoutingEvent)
  window.addEventListener('single-spa:routing-event', emitLocationDidChange)
  window.addEventListener('bpr:navigate', navigateByApplicationRequest)
  window.addEventListener(
    'bpr:relayLocationChange',
    relayLocationChangeFromApplication
  )
  window.addEventListener(
    'bpr:relayProfileChange',
    relayProfileChangeFromApplication
  )
  window.addEventListener(
    'bpr:focusMainContentNode',
    queuedFocusMainContentNode
  )
  window.addEventListener('bpr:changeAppStatus', emitStatusDidChange)

  singleSpa.start()

  return {
    initialActiveApps: platform._initialActiveAppIds,
    state: platform._getState(),
    stop: () => {
      window.removeEventListener('single-spa:app-change', emitAppDidChange)
      window.removeEventListener(
        'single-spa:before-routing-event',
        beforeRoutingEvent
      )
      window.removeEventListener(
        'single-spa:routing-event',
        emitLocationDidChange
      )
      window.removeEventListener(
        'bpr:relayLocationChange',
        relayLocationChangeFromApplication
      )
      window.removeEventListener(
        'bpr:relayProfileChange',
        relayProfileChangeFromApplication
      )
      window.removeEventListener('bpr:navigate', navigateByApplicationRequest)
      window.removeEventListener(
        'bpr:focusMainContentNode',
        queuedFocusMainContentNode
      )
      window.removeEventListener('bpr:changeAppStatus', emitStatusDidChange)

      return Promise.all(
        platform._appIds
          .map((appId) =>
            singleSpa.unloadApplication(appId, { waitForUnmount: true })
          )
          // this is because single spa doesn't have a real API for stopping,
          // see https://github.com/CanopyTax/single-spa/issues/121
          //
          // for now this will do what we want; it will deactivate all active apps
          .concat([navigate(platform, '/!@#$%^&*()__nowhere__')])
      )
    },
  }
}

export const getActiveApps = (platform) => platform._getState().activeApps
export const getLocation = (platform) =>
  platform._getState().location.pathname + platform._getState().location.search
