import { rearrangeList } from 'list-placement'

export const BRIDGE_APPS_ID = 'bridge-apps'

export const getBridgeAppsNode = () => document.getElementById(BRIDGE_APPS_ID)

export const prepareMainDOMNode = ({ parentNode }) => {
  const mainNode = document.createElement('div')
  mainNode.setAttribute('id', BRIDGE_APPS_ID)
  parentNode.appendChild(mainNode)
  return mainNode
}

export const prepareDOMForApplications = (
  applicationSpecs,
  { mainNode, parentNode }
) =>
  rearrangeList(
    {
      orderKey: 'dom_position',
      idKey: 'id',
    },
    applicationSpecs
  ).reduce((map, spec) => {
    const node = document.createElement('div')

    node.id = spec.id

    if (spec.role === 'main') {
      mainNode.appendChild(node)
    } else if (
      spec.dom_position &&
      spec.dom_position.at &&
      spec.dom_position.at > 0
    ) {
      parentNode.appendChild(node)
    } else {
      parentNode.insertBefore(node, mainNode)
    }

    map[spec.id] = node

    return map
  }, {})

export const setDocumentLocale = (locale) => {
  document.documentElement.setAttribute('lang', locale)
}
