import DisabledAppsHandler from './DisabledAppsHandler'
import { isFeatureEnabled } from './services/feature-flags/feature-flag-system'

function isRuleValid(rule, featureFlags) {
  if (rule.feature !== undefined) {
    return isFeatureEnabled(featureFlags, rule.feature) === true
  }

  if (rule.feature_disabled !== undefined) {
    return isFeatureEnabled(featureFlags, rule.feature_disabled) !== true
  }

  return false
}

// TODO: stop greedy tests, interpolate identifiers instead
export function createActiveAppHandler({ id, routes }, featureFlags) {
  return (location) => {
    if (DisabledAppsHandler.isDisabled(id)) {
      return false
    }

    const path = location.pathname + location.search
    return routes.some((route) => {
      if (typeof route === 'object') {
        const hasAccess = (route.access_rules || []).every((rule) =>
          isRuleValid(rule, featureFlags)
        )

        return hasAccess && path.match(route.path)
      }

      return path.match(route)
    })
  }
}

export class AccountProductError extends Error {
  constructor(...args) {
    super('Does not have access to this product', ...args)
  }
}

export function verifyProductAccess(specProducts = [], accountProducts) {
  return new Promise((resolve) => {
    const accountHasProducts = specProducts.every((specProduct) =>
      accountProducts.includes(specProduct)
    )
    if (!accountHasProducts) {
      throw new AccountProductError()
    }
    resolve()
  })
}
