/**
 * Asynchronously loads google analytics as a separate chunk.
 */
export function loadGoogleAnalyticsLib() {
  return import(/* webpackChunkName: "ga" */ 'firebase/app').then((firebase) =>
    Promise.all([
      import(/* webpackChunkName: "ga" */ 'firebase/analytics'),
      import(/* webpackChunkName: "ga" */ 'firebase/performance'),
    ]).then(() => firebase)
  )
}
