import { CSRF_HEADER_NAME, getCsrfToken } from '@get-bridge/tapestry-sdk'

/**
 * A generic API client for fetching data in Tapestry.
 *
 * @param {string} url - the URL to fetch
 * @param {Object} [options={}] - overrideable options
 * @param {data} [options.data] - data to POST/PUT, etc. Is JSON stringified by client
 * @param {'GET'|'POST'|'PUT'|'DELETE'} [options.method='GET'] - the method to use for the request
 * @param {Object} [options.headers] - any optional headers to include in request.
 * @returns {Promise<any>}
 */
export async function apiClient(
  url,
  { data, method = 'GET', headers: customHeaders, ...customConfig } = {}
) {
  let headers = {
    Accept: 'application/json, text/plain, */*',
    // Add CSRF header.
    [CSRF_HEADER_NAME]: method !== 'GET' ? getCsrfToken() : undefined,
    ...customHeaders,
  }
  if (data && !headers['Content-Type']) {
    headers['Content-Type'] = 'application/json'
  }
  const config = {
    method: method,
    body: data ? JSON.stringify(data) : undefined,
    headers: headers,
    ...customConfig,
  }

  return window.fetch(url, config).then(async (response) => {
    if (response.ok) {
      return await response.json()
    } else {
      return Promise.reject(response.statusText)
    }
  })
}
