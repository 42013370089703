import {
  curry,
  difference,
  filter,
  find,
  intersection,
  map,
  partial,
  pipe,
  propEq,
  tap,
} from 'ramda'
import StaticMap from './StaticMap'
import {
  loadApplicationVariables,
  loadScriptsInOrder,
  AssetLoadError,
} from './ResourceLoader'
import {
  createActiveAppHandler,
  verifyProductAccess,
  AccountProductError,
} from './ActiveAppHandler'
import { getEntryResourceUrls } from './entry-resources'
import { createHolder } from './utils'

const LOAD_ERROR_KEY = 'bridge-runtime.__LAST_LOAD_ERROR'
const getNamedExport = (spec) => (exports) => exports[spec.id]
const Status = {
  Valid: 'Valid',
  Invalid: 'Invalid',
  Deprecated: 'Deprecated',
}
const productHomes = {
  career: '/career/development-plan',
  connect: '/connect/search',
  engage: '/engage',
  learn: '/learner/courses',
  perform: '/talent/home',
}
const productHomesPriority = ['perform', 'learn', 'career', 'connect', 'engage']

/**
 * Morph a bridge-platform application spec into a Single-Spa application.
 *
 * @param {Object} context
 * @param {Object.<applicationId: String, RouteListing>} context.routes
 *        The routes handled by the application. This is used to figure out
 *        whether the application is active.
 *
 * @param {Platform} context.platform
 *
 * @param {Capability.Application} spec
 *
 * @return {SingleSpa.Application}
 */
export const morphApplication = curry(
  (
    {
      domNodes,
      exports,
      featureFlags,
      session,
      account,
      applications,
      hosts,
      customProps = {},
      windowRef = window,
    },
    spec
  ) => {
    const moduleValue = createHolder()
    const unloadingPageValue = createHolder(false)

    const domNode = domNodes[spec.id]
    const getExport = spec.getExportFn || getNamedExport(spec)
    const api = {
      domNode,
      featureFlags,
      session,
      applications,
      account,
      hosts,
      customProps,
    }
    const accountProducts = account.config.products.map(
      (product) => product.productName
    )

    windowRef.addEventListener('beforeunload', () => {
      unloadingPageValue.set(true)
    })

    const loadApplication = () =>
      verifyProductAccess(spec.products, accountProducts)
        .then(() => loadApplicationVariables(spec))
        .then((variables) =>
          loadScriptsInOrder(getEntryResourceUrls({ ...spec, variables }))
        )
        .then(partial(getExport, [exports]))
        // eslint-disable-next-line no-use-before-define
        .then(morphDefinition(api))
        .then(
          tap((loadedModule) => {
            moduleValue.set(loadedModule)
          })
        )
        .catch((err) => {
          if (err instanceof AssetLoadError) {
            // Indicates that one or more assets could not be loaded, with the
            // failed assets attached to the error's "assets" property.
            // If it's a load error, attempt to reload the page, unless the browser has already
            // tried and failed to reload the same assets (indicated by the value in local storage)
            const currentLoadError = JSON.stringify(err.assets)
            const lastLoadError = windowRef.localStorage.getItem(LOAD_ERROR_KEY)
            if (lastLoadError !== currentLoadError) {
              windowRef.localStorage.setItem(LOAD_ERROR_KEY, currentLoadError)
              if (!unloadingPageValue.get()) {
                windowRef.location.reload(true)
              }
            } else {
              console.error(
                `[RUNTIME] Attempted to reload the following assets too many times: ${err.assets}. User should manually refresh the page.`
              )
              throw err
            }
          } else if (err instanceof AccountProductError) {
            const redirectProduct = productHomesPriority.find((product) =>
              accountProducts.includes(product)
            )
            // TODO: Show a global toast to notify the user why they are being redirected
            console.warn(
              `[RUNTIME] Attempted to access a product this account does not have access to (${spec.name}). Redirecting to ${redirectProduct}`
            )
            windowRef.location.replace(productHomes[redirectProduct])
          } else {
            // Not a handled error? Rethrow the error.
            throw err
          }
        })

    const isActive = createActiveAppHandler(spec, featureFlags)

    return {
      id: spec.id,
      isActive,
      loadApplication,
      getModule: () => moduleValue.get(),
    }
  }
)

export const getAvailableAppModulesFrom = (singleSpaApplications) =>
  pipe(
    map(getIn(singleSpaApplications)),
    // bandaid until single spa has an actual reset routine, this is only necessary
    // in test since we re-use single spa with different apps
    filter(Boolean),
    map((app) => app.getModule())
  )

const getIn = (apps) => (id) => find(propEq('id', id))(apps)

export const morphDefinition = curry((api, def) => {
  if (def && typeof def === 'object' && typeof def.version === 'number') {
    return adapterVersionCurrent(api, def)
  }
  if (typeof def === 'function') {
    return adapterVersionDeprecated(api, def)
  }

  return adapterVersionUnknown(api, def)
})

function adapterVersionCurrent(api, def) {
  const appStateValue = createHolder(null)
  const globalVerifierValue = createHolder()

  const {
    mount,
    featureFlagsDidChange = Function.prototype,
    locationDidChange = Function.prototype,
    identityDidChange = Function.prototype,
    statusDidChange = Function.prototype,
    unmount,
  } = def

  return {
    status: Status.Valid,
    version: def.version,
    bootstrap() {
      return Promise.resolve()
    },

    mount({ customProps: { getPreviousPathname, getCurrentPathname } }) {
      globalVerifierValue.set(createRestoreVerification())
      const mountParam = {
        ...api,
        history: { getPreviousPathname, getCurrentPathname },
      }
      return mount(mountParam).then((appState) => {
        appStateValue.set(appState)
      })
    },

    /**
     * @param statusData
     * @param statusData.statusCode status code related to the status page (403, 404, etc)
     */
    statusDidChange(statusData) {
      return statusDidChange(appStateValue.get(), statusData)
    },

    // TODO: refactor to accept specific arguments
    locationDidChange(...args) {
      return locationDidChange(...[appStateValue.get()].concat(args))
    },

    identityDidChange(nextSession, platformState) {
      return identityDidChange(nextSession, appStateValue.get(), platformState)
    },

    // TODO: refactor to accept specific arguments
    featureFlagsDidChange(...args) {
      return featureFlagsDidChange(...[appStateValue.get()].concat(args))
    },

    unmount({ customProps: { getPreviousPathname, getCurrentPathname } }) {
      const unmountParam = {
        ...appStateValue.get(),
        history: { getPreviousPathname, getCurrentPathname },
      }
      return unmount(unmountParam).then(() => {
        appStateValue.set(null)
        globalVerifierValue.get()()
      })
    },
  }
}

function adapterVersionDeprecated(api, def) {
  const globalVerifierValue = createHolder()
  const state = StaticMap.create()

  const app = def(api, state)
  const { bootstrap, mount, locationDidChange, unmount } = app

  return {
    status: Status.Deprecated,

    bootstrap() {
      return bootstrap()
    },

    mount() {
      globalVerifierValue.set(createRestoreVerification())
      StaticMap.clear(state)

      return mount()
    },

    locationDidChange,

    unmount() {
      return unmount().then(() => {
        StaticMap.clear(state)
        globalVerifierValue.get()()
      })
    },
  }
}

function adapterVersionUnknown() {
  return {
    status: Status.Invalid,
    bootstrap: () => Promise.reject('Application has an unidentified version'),
    mount: () => Promise.resolve(),
    unmount: () => Promise.resolve(),
  }
}

const windowChanged = (str) => `Window not restored during unmount. ${str}`

function createRestoreVerification() {
  const original = Object.assign({}, window)
  const originalKeys = Object.keys(original)
  const INTERNAL_KEYS = [
    'BRIDGE_PLATFORM_STATE',
    'exports',
    'BRIDGE_PLATFORM_EXPORTS',
  ]

  return function () {
    const currentKeys = Object.keys(window)

    const newKeys = difference(currentKeys, originalKeys)
    // TODO IE11 adds this?
    if (newKeys.length && newKeys.indexOf('performance') === -1) {
      console.error(windowChanged(`keys added: ${newKeys}`))
    }

    const removedKeys = difference(originalKeys, currentKeys)
    if (removedKeys.length) {
      console.error(windowChanged(`keys removed: ${removedKeys}`))
    }

    const changes = intersection(originalKeys, currentKeys)
      .filter((key) => window[key] !== original[key])
      .filter((key) => INTERNAL_KEYS.indexOf(key) === -1)
    if (changes.length) {
      console.error(windowChanged(`keys changed: ${changes}`))
    }
  }
}
