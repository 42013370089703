import { curryN, pathOr } from 'ramda'

const DEFAULT_HOST = 'local.bridgeops.sh:8443'

export const buildHosts = curryN(2, (hosts = {}, account) => {
  const { subdomain, vanityDomain, vanityDomainState } = pathOr(
    { subdomain: 'bridge' },
    ['host'],
    account
  )
  const hasVanity =
    vanityDomainState === 'vanity_domain_active' && !!vanityDomain
  return Object.keys(hosts).reduce((obj, key) => {
    if (key === 'learn') {
      obj[key] = (hasVanity ? buildVanityUrl : buildHostUrl)(hosts[key])
    } else {
      obj[key] = buildHostUrl(hosts[key])
    }
    return obj
  }, {})

  function buildVanityUrl() {
    return `https://${vanityDomain}`
  }

  function buildHostUrl(host = DEFAULT_HOST) {
    const [, p, h] = host.match(/(?:(https?):\/\/)?(.*)/)
    return `${p || 'https'}://${subdomain}.${h}`
  }
})
