import * as singleSpa from 'single-spa'

/**
 * a handler for apps marked as disabled.
 * _disabledList is organized by namespaces in order to allow strategic disable/enable actions
 */
function DisabledAppsHandler() {
  const _disableList = {}

  return {
    disableApps: (appIds, _namespace) => {
      if (!_disableList[_namespace]) {
        _disableList[_namespace] = []
      }

      appIds.forEach((appId) => {
        if (!_disableList[_namespace].includes(appId)) {
          _disableList[_namespace].push(appId)
        }
      })

      return singleSpa.triggerAppChange()
    },

    emptyDisableList: (_namespace) => {
      if (_disableList[_namespace]) {
        _disableList[_namespace].splice(0)
      }
    },

    isDisabled: (appId) =>
      Object.values(_disableList).some((list) => list.includes(appId)),
  }
}

const handlerInstance = new DisabledAppsHandler()

export default handlerInstance
