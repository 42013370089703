/**
 * @param {string} keyName - A key to identify the value.
 * @param {any} keyValue - A value associated with the key.
 * @param {number} ttl- Time to live in days.
 */
const setLocal = (keyName, keyValue, ttl) => {
  const data = {
    value: keyValue, // store the value within this object
    ttl: Date.now() + ttl * 1000 * 60 * 24, // store the TTL (time to live).
  }

  // store data in LocalStorage
  localStorage.setItem(keyName, JSON.stringify(data))
}

/**
 * @param {string} keyName - A key to identify the data.
 * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
 */
const getLocal = (keyName) => {
  const data = localStorage.getItem(keyName)
  let item
  try {
    item = JSON.parse(data)
  } catch (e) {
    return null
  }
  if (!item) {
    // if no value exists associated with the key, return null
    return null
  }

  if (Date.now() >= item.ttl) {
    localStorage.removeItem(keyName)
    return null
  }

  // return data if not expired
  return item.value
}

// Return whether the user is selected for monitoring tool tracking.
// If no selection exists, it randomly selects and persists the decision until number of days pass equal to daysUntilReassess.
/**
 * @param {string} localKeyName - A key to identify the data. Should be unique to each monitoring service.
 * @param {number} selectedUserPercent - The percentage of total users that should be randomly selected for monitoring.
 * @param {number} daysUntilReassess - The number of days until the user is re-evaluated for tracking for the particular monitoring service.
 */
export const isSelectedUser = (
  localKeyName,
  selectedUserPercent,
  daysUntilReassess
) => {
  const isSelected = getLocal(localKeyName)

  if (isSelected !== null) {
    return isSelected
  }

  return randomlySelectUser(
    localKeyName,
    selectedUserPercent,
    daysUntilReassess
  )
}

export const randomlySelectUser = (
  localKeyName,
  selectedUserPercent,
  daysUntilReassess
) => {
  // If no locally stored data found, then randomly choose whether the user is selected to be part of the tracking service.
  // And persist in browser local storage for future reference.
  const isSelected = Math.random() < selectedUserPercent
  setLocal(localKeyName, isSelected, daysUntilReassess)

  return isSelected
}
