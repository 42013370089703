import { start } from '..'

const NOOP_PROMISE = () => Promise.resolve()

function onErrorDefault(err) {
  console.error(err)
  throw err
}

function onLoad() {
  document.getElementById('bridge-platform-loading-indicator').remove()
  document
    .getElementById('bridge-platform')
    .classList.remove('bridge-platform-apps--loading')
}

function onStart({ enabledApplications, initialApplications }) {
  document.getElementById('boot_error_log').remove()
  console.log('Platform (enabled) applications:', enabledApplications)
  console.log('Platform (active) applications:', initialApplications)
}

export function startApp({
  beforeStart = NOOP_PROMISE,
  onError = onErrorDefault,
  services = {},
} = {}) {
  return beforeStart()
    .then((onInit) =>
      start({
        rootDOMNode: document.getElementById('bridge-platform'),
        onInit,
        onLoad,
        services,
      })
    )
    .then(onStart)
    .catch(onError)
}
