import * as R from 'ramda'
import { transformCdn, transformManifest } from './manifest-utils'

function createError(response) {
  const error = new Error(response.statusText)
  error.response = response
  return error
}

const toManifests = ({ cdnKey, ignoreApps }) =>
  R.pipe(
    // Filter out apps whose manifest entries should be ignored.
    R.pickBy((_, appId) => ignoreApps.indexOf(appId) < 0),
    R.values,
    R.map(transformManifest(transformCdn(cdnKey)))
  )

/**
 * Returns the manifest files to use to configure the app.
 *
 * @param {Object} params
 * @param {string} params.cdnKey - the cdnKey to use
 * @param {string[]} params.ignoreApps - the IDs of apps to ignore
 * @param {string} params.manifestUrl - the URL to fetch the manifest entries from
 * @returns {Promise<Manifest[]>} the fetched manifests
 */
export function fetchRemoteManifests({ cdnKey, ignoreApps, manifestUrl }) {
  return window
    .fetch(manifestUrl)
    .then((response) =>
      response.ok ? response.json() : Promise.reject(createError(response))
    )
    .then(toManifests({ cdnKey, ignoreApps }))
}
