import * as R from 'ramda'

const PLUGIN_NAME = 'BridgeRuntime::DisableHCMForApp'

const appsToDisableHCM = R.pipe(
  R.filter(R.propEq('service', PLUGIN_NAME)),
  R.map(R.path(['parameters', 'id']))
)
const isAppHCMDisabledFn = (appsToDisable, getActiveApps) => () =>
  R.intersection(appsToDisable, getActiveApps()).length > 0

export const isHighContrastEnabled = R.pathEq(
  ['user', 'theme'],
  'high_contrast'
)

export const isUnderlineLinksEnabled = R.pathSatisfies(Boolean, [
  'user',
  'underlineLinks',
])

export const isHCMDisabledFn = (plugins, getActiveApps) =>
  isAppHCMDisabledFn(appsToDisableHCM(plugins), getActiveApps)

export function createHolder(initialValue) {
  let value = initialValue
  return {
    get() {
      return value
    },
    set(newValue) {
      value = newValue
    },
  }
}
