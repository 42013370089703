/**
 * Handles a queue that will hold functions execution until application gets bootstrapped
 */
function BootstrapQueue() {
  const _queue = []
  let _bootstrapComplete = false

  return {
    /**
     * calls callbackFn (using setTimeout for unblocking running)
     * if application bootstrap is complete;
     * adds it to the queue otherwise
     */
    runWhenReady(callbackFn) {
      if (_bootstrapComplete) {
        setTimeout(callbackFn)
      } else {
        _queue.push(callbackFn)
      }
    },
    invokeQueued() {
      _bootstrapComplete = true

      while (_queue.length) {
        setTimeout(_queue.shift())
      }
    },
  }
}

export default BootstrapQueue
