const UNAUTHENTICATED_ROUTES = [
  /^\/error/,
  /^\/forgot-password/,
  /^\/learner\/terms/,
  /^\/learner\/privacy_policy/,
  /^\/login/,
  /^\/no_user/,
  /^\/privacy_policy/,
  /^\/reset-password\/.+/,
  /^\/signed-out/,
  /^\/terms/,
  /^\/failure/,
  /^\/user-setup\/.+/,
]

/**
 * Determines whether current route requires authentication.
 *
 * @param {string} uri the path to validate
 * @returns {boolean} true if path is an unauthenticated route, otherwise false
 */
export function isUnauthenticatedRoute(uri) {
  return UNAUTHENTICATED_ROUTES.some((route) => route.test(uri))
}

export function isIE(userAgent) {
  return (
    userAgent.indexOf('MSIE ') > 0 ||
    userAgent.indexOf('Trident/') > 0 ||
    userAgent.indexOf('Edge/') > 0
  )
}

// Find out whether the session is eligible for refresh based on the API error
// response: Kong responds with 401 on retrieval errors while Astral with 404.
//
// (Axios::Error): Bool
export function shouldSessionBeRefreshed(
  { request, response },
  { windowRef = window } = {}
) {
  // IE has a bug where it treats a 401 as a generic network failure, with a
  // status 0. In IE, when we get the 401 from /sso/users/self, the status may
  // be 0, and the response may be undefined. This only seems to happen on some
  // people's machines, for some reason, even if they're on the same VM, same
  // image, same version of ie.
  //
  // :shakefist: :ie:
  //
  // https://stackoverflow.com/questions/16081267/xmlhttprequest-status-0-instead-of-401-in-ie-10
  if (
    isIE(windowRef.navigator.userAgent) &&
    !response &&
    request.status === 0
  ) {
    return true
  }
  if (response.status === 401) {
    return true
  }

  return false
}
